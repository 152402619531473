<template>
  <div>
    <b-button
      size="sm"
      class="button-export-style ml-2 pl-2 pr-2 new-color-export-facture"
      title="Télecharger xls facture "
      @click="openModalOblige"
    >
      <font-awesome-icon icon="file-export"
    /></b-button>
    <!-- Extract OBLIGEE FACTURE Modal -->
    <b-modal
      ref="extract-modal-obligee-facture"
      id="extract-modal-obligee-facture"
      no-close-on-backdrop
      :hide-footer="true"
      :title="'Générer un xls'"
      @hidden="hideModal('extract-modal-obligee-facture')"
      modal-class="modal-extaction-bdd-frais"
    >
      <b-form-group label="Type de facture:" class="input-modal-champ">
        <b-form-checkbox
          @change="changeAllCheckTypes"
          :checked="computedCheckAll"
          class="check-th-details"
          >Tous</b-form-checkbox
        >
        <b-form-checkbox-group
          class="check-th-details"
          id="checkbox-group-1"
          v-model="type"
          :options="getTypeListFactureObligee"
          name="flavour-1"
          stacked
          value-field="value"
          text-field="text"
        ></b-form-checkbox-group>
      </b-form-group>

      <b-form-group
        id="fieldset-horizontal"
        label-cols-sm="5"
        label-cols-lg="3"
        content-cols-sm
        content-cols-lg="9"
        label="Modèle : "
        label-for="extract-modal"
      >
        <b-form-select
          id="extract-modal"
          v-model="selectedModel"
          :options="computedListModelToExtract"
          required
          class="b-form-select-raduis"
        ></b-form-select>
      </b-form-group>
      <div class="form-modal-custom-style mt-2">
        <div class="messageError">
          <div v-if="errorAppeliPaiementThObligeeExportXls" class="error">
            <ul v-if="Array.isArray(errorAppeliPaiementThObligeeExportXls)">
              <li
                v-for="(e, index) in errorAppeliPaiementThObligeeExportXls"
                :key="index"
              >
                {{ e }}
              </li>
            </ul>
            <span v-else>{{ errorAppeliPaiementThObligeeExportXls }}</span>
          </div>
        </div>
        <div class="messageError" v-if="error">
          <div class="error text-center">{{ error }}</div>
        </div>
        <div class="actionModel">
          <b-button
            class="button-valide-style"
            @click="handleExport"
            :disabled="chargementAppelPaiementThObligeeExportXlsFacture"
          >
            <span>
              Générer
              <div
                v-if="chargementAppelPaiementThObligeeExportXlsFacture"
                class="loading ml-2"
              >
                <div class="spinner-border" role="status"></div></div
            ></span>
          </b-button>
        </div>
      </div>
    </b-modal>
    <!-- END Extract OBLIGEE FACTURE Modal -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  props: {
    resetModalExportFacture: { required: true },
  },
  data() {
    return {
      type: [],
      selectedModel: null,
      error: null,
    };
  },
  methods: {
    ...mapActions(['getAllModelExports']),
    openModalOblige() {
      if (this.computedListModelToExtract.length) {
        this.selectedModel = this.computedListModelToExtract[0]?.value;
      }
      this.$refs['extract-modal-obligee-facture'].show();
    },
    handleExport() {
      this.error = null;
      if (this.type.length == 0) {
        this.error = 'Il faut sélectionner au moins un type';
      } else {
        this.$emit('exportFactureXls', this.selectedModel, this.type);
      }
    },
    resetModal(ref) {
      this.hideModal(ref);
      this.selectedModel = 'vertical';
    },
    hideModal(ref) {
      this.$refs[ref].hide();
    },
    changeAllCheckTypes() {
      if (this.computedCheckAll == false) {
        this.type = this.getTypeListFactureObligee.map((item) => item.value);
      } else {
        this.type = [];
      }
    },
  },
  computed: {
    ...mapGetters([
      'chargementAppelPaiementThObligeeExportXlsFacture',
      'errorAppeliPaiementThObligeeExportXls',
      'getTypeListFactureObligee',
      'models',
    ]),
    computedListModelToExtract() {
      if (this.models.length) {
        return this.models.map((item) => {
          return {
            text: item.name,
            value: item.id,
          };
        });
      }
      return [];
    },
    computedCheckAll() {
      if (this.type.length == this.getTypeListFactureObligee.length) {
        return true;
      }
      return false;
    },
  },
  watch: {
    resetModalExportFacture() {
      this.resetModal('extract-modal-obligee-facture');
    },
  },
  mounted() {
    this.type = this.getTypeListFactureObligee.map((item) => item.value);
    this.getAllModelExports();
  },
};
</script>

<style scoped lang="scss">
.new-color-export-facture {
  background-color: #0e0c5e;
}
</style>
<style lang="scss">
// .test {
//   .custom-checkbox {
//     display: flex;
//     flex-direction: row;
//     flex-wrap: wrap;
//     width: 100%;
//     align-items: flex-start;
//   }
// }
</style>
